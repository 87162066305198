/*global _, $, jQuery, MatchHeightHelper*/
import Helper from './services/Helper/Helper.js';
import Select from './widgets/Select/Select.js';

(function($) {
  $(function() {
    var
      $body = $('body'),
      country = $body.data('country') ? $body.data('country') : 'fr'
    ;

    // Init linkify
    require('linkifyjs/jquery')($, document);
    $('.linkify').linkify();

    // Init Fancybox
    require('fancybox/dist/js/jquery.fancybox.cjs.js')($);
    $('.fancybox').fancybox();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ('undefined' !== typeof $.fn.validate && $.isFunction($.fn.validate)) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        'highlight': function(element) {
          // Add the .has-error class to the form-group.
          $(element).closest('.form-group').addClass('has-error');
        },
        'unhighlight': function(element) {
          // Remove the .has-error class from the form-group.
          $(element).closest('.form-group').removeClass('has-error');
        },
        'errorElement': 'span',
        'errorClass': 'help-block',
        'errorPlacement': function(error, element) {
          // Handle the error placement for checkbox & radio.
          if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        }
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod('cemail', function(value, element, regexp)  {
        regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

        return this.optional(element) || regexp.test(value);
      }, 'Please enter a valid email address');

      $.validator.addMethod('pattern', function(value, element, regexp)  {
        var pattern = new RegExp(regexp);

        return this.optional(element) || pattern.test(value);
      });

      // $.validator.addMethod('alphanumeric', function(value, element) {
      //   var regexp = new RegExp(/^[\u4e00-\u9fff_A-Za-zа-яёЁÀ-Ÿ][\u4e00-\u9fff_a-zа-яёЁÀ-Ÿ0-9\-\s]+$/i);

      //   return regexp.test(value);
      // }, 'Letters, numbers, spaces or dashes only');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $('.js-contact-form, .js-newsletter-form').each(function(i, e) {
        $(e).validate({
          'ignore': [],
          'rules': {
            'front_message_type[email]': {
              'email': false,
              'cemail': true
            },
            'front_newsletter_type[email]': {
              'email': false,
              'cemail': true
            },
            'hiddenRecaptcha': {
              required: function() {
                return grecaptcha.getResponse() == '' ? true : false;
              }
            }
          }
        });
      });

      $('.js-press-review-form').validate({
        'rules': {
          'front_press_review_type[pressReviewFields][costCenter]': {
            pattern: "^[0-9]{4}$",
          },
          'front_press_review_type[pressReviewFields][entityCode]' : {
            pattern: "^[a-zA-Z]{3}[0-9]{3}$",
          },
        },
        'messages': {
          'front_press_review_type[pressReviewFields][costCenter]': {
            pattern: $('[name="front_press_review_type[pressReviewFields][costCenter]"]').data('msg-error')
          },
          'front_press_review_type[pressReviewFields][entityCode]' : {
            pattern: $('[name="front_press_review_type[pressReviewFields][entityCode]"]').data('msg-error')
          },
        }
      })

      $('.max_length_rule').each(function() {
        $(this).rules('add', {
          maxlength: 100,
          alphanumeric: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.email_rule').each(function() {
        $(this).rules('add', {
          email: false,
          cemail: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.js-press-review-form input').blur(function() {
        var isValid = $(this).attr('aria-invalid');
        var isValid = isValid === 'true';

        $('#front_press_review_type_submit').attr('disabled', isValid);
      })
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();

    $('.js-match-height .js-match-height-item').matchHeight();

    $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      MatchHeightHelper.update($container[0], true, '.js-match-height .card');
    });

    $('.js-buckle--assets').on('showing.jq.offie-dropdown', '[data-toggle="offie-dropdown"]', function (event) {
      var buckle = $(event.target).closest('.js-buckle--folder').data('buckle');

      // Fetch content of the folder.
      buckle.fetch({});
    });

    $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      // Update the grid.
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      'format': 'yyyy-mm-dd',
      'startView': 'year',
      'minView': 'month',
      'fontAwesome': true,
      'autoclose': true
    };

    $('#front_search_type_start').datetimepicker(dateTimePickerOptions);
    $('#front_search_type_end').datetimepicker(dateTimePickerOptions);

    require('./modules/share.js');

    Helper
      .convertToArray(document.querySelectorAll('select'))
      .map((select) => new Select(select).open())
    ;

    // Press Review
    var pressReviewLink = $('.section--press_review-jumbotron .section-btn').attr('href');

    $('.section--press-review-index .section-btn').attr('href', pressReviewLink);

    function toggleAriaExpanded(element) {
      let ariaExpandedAttr = element.attr('aria-expanded') ?? 'false';

      element.attr('aria-expanded', ariaExpandedAttr === 'false' ? 'true' : 'false');
    }

    $('.btn-toggle-box').on('click', function() {
      $('.icon-search, .icon-arr').toggleClass('active');
      $('.form--clientsearch').toggleClass('active');
    });

    $('.btn-toggle-box').on('keydown', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();

        $('.icon-search, .icon-arr').toggleClass('active');
        $('.form--clientsearch').toggleClass('active');

        if ($('.form--clientsearch').hasClass('active')) {
          $('.form--clientsearch .form-control').focus();
        }
      }
    });

    // Footer contact block
    $('.section--contact-footer .card-btn').on('click', function() {
      $(this).toggleClass('rotated');
      $('.section--contact-footer .card-text').slideToggle();
      toggleAriaExpanded($(this));
    });

    $('.header-language .nav-item').on('click', function() {
      $('.header-language .nav-item').not($(this)).removeClass('active-lang');
      $(this).addClass('active-lang');
    });

    $('.header-language .active-lang').on('click', function() {
      $(this).parent().toggleClass('active');
    })

    // Newsletter Page Contact Block
    const ctaEmail = document.getElementById('ctaEmail'),
          ctaPhone = document.getElementById('ctaPhone'),
          email    = document.getElementById('contactEmail'),
          phone    = document.getElementById('contactPhone')
    ;

    if (ctaEmail) {
      ctaEmail.addEventListener('click', function () {
        ctaEmail.remove();
        email.style.display = 'inline-block';
      });
    }

    if (ctaPhone) {
      ctaPhone.addEventListener('click', function () {
        ctaPhone.remove();
        phone.style.display = 'inline-block';
      });
    }

    $('.header-burger').on('click', function() {
      $(this).toggleClass('active');
      $('body').css('overflow', 'hidden');
      $('.block-menu-detail').toggleClass('active');
    });

    $('.close-menu-btn').on('click', function() {
      $('body').css('overflow', 'inherit');
      $('.block-menu-detail').removeClass('active');
    });

    $('.js-icon-share').on('click', function() {
      $(this).addClass('hidden');
      $(this).next().addClass('active');
      $('.overlay-share').addClass('active');
    });

    $('.overlay-share, .btn-close-share').on('click', function() {
      $('.overlay-share').removeClass('active');
      $(this).removeClass('active');
      $('.overlay-share').next().children('.list--share').removeClass('active');
      $('.js-icon-share').removeClass('hidden');
    });

    let countDisplay = 1;
    const navAccessEl = $(".med-nav-access");

    const scrollToTop = () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };

    const toggleNavAccess = () => {
      navAccessEl.toggle();
    };

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Tab') {
        if (countDisplay === 1) {
          scrollToTop();
          toggleNavAccess();
          navAccessEl.focus();
        } else if (countDisplay > 1 && navAccessEl.is(":visible")) {
          toggleNavAccess();
        }
        countDisplay++;
      }
    });

    document.addEventListener('click', () => {
      if (navAccessEl.is(":visible")) {
        toggleNavAccess();
      }
      countDisplay = 1;
    });

    // Didomi - Display Banner
    let didomiBtn = document.getElementById('didomi-banner-btn') ?? null;
    if (didomiBtn != null) {
      didomiBtn.addEventListener('click', function() {
        window.Didomi?.preferences.show();
      });
    }

    function setProperty() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    }

    setProperty();

    function assetCenterMode() {
      const items = $('.layout--show.layout--web_publication-show .section--assets .gallery-list .gallery-item');
      const totalItems = items.length;
      const itemWidth = items.outerWidth(true);
      const carouselInner = $('.layout--show.layout--web_publication-show .section--assets .gallery-list');

      if(totalItems >= 2) {
        carouselInner.prepend(items.last().clone().addClass('clonned'));
        carouselInner.prepend(items.eq(-2).clone().addClass('clonned'));
        carouselInner.append(items.first().clone().addClass('clonned'));
        carouselInner.append(items.eq(1).clone().addClass('clonned'));

        let currentIndex = 1;
        const totalAdjustedItems = totalItems + 2;

        carouselInner.css('transform', `translateX(${-itemWidth * 1}px)`);

        function updateCarousel(isNext = true) {
          if (isNext) {
            currentIndex++;
          } else {
            currentIndex--;
          }
          carouselInner.css('transition', 'transform 0.5s ease-in-out');
          carouselInner.css('transform', `translateX(${-itemWidth * currentIndex}px)`);
          carouselInner.one('transitionend', function() {
            if (currentIndex === 0) {
              currentIndex = totalItems;
              carouselInner.css('transition', 'none');
              carouselInner.css('transform', `translateX(${-itemWidth * currentIndex}px)`);
            } else if (currentIndex === totalAdjustedItems - 1) {
              currentIndex = 1;
              carouselInner.css('transition', 'none');
              carouselInner.css('transform', `translateX(${-itemWidth * currentIndex}px)`);
            }
          });
        }

        $('.section--assets .btn-next').on('click', function() {
          updateCarousel(true);
        });

        $('.section--assets .btn-prev').on('click', function() {
          updateCarousel(false);
        });

        updateCarousel();
      }
    }

    assetCenterMode();
  });
}(jQuery));
